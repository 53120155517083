import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import ConvertKit from 'convertkit-react'
import { Link } from 'gatsby'

const SignUp = () => {
  return (
    <Layout>
      <SEO title="Laurie on Tech | Newsletter Sign up" />
      <Hero title="SIGN UP" description="I may some day send a newsletter" />
      <section id="about" className="special wrapper container style4 post">
        <p className="bio p book">
          I made this form for my April Fool's Day{' '}
          <Link to="/book-launch">Book Launch</Link>, but I may some day use it
          for real. So feel free to subscribe!
        </p>
        <ConvertKit className="book-button" formId="2174147" />
      </section>
    </Layout>
  )
}

export default SignUp
